import {
    Component,
    OnInit,
    Injectable,
    Output,
    EventEmitter
  } from '@angular/core';
  
  import { NgForm } from '@angular/forms';
import { ApiAlbaranService } from '../../services/api-albaran.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

  @Injectable({
    providedIn: 'root'
  })
  @Component({
    selector: 'new-wool-type',
    templateUrl: './new-wool-type.component.html',
    styleUrls: ['./new-wool-type.component.scss']
  })
  export class NewWoolTypeComponent implements OnInit {
    public name: string = '';
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
    constructor(
        private albaranService: ApiAlbaranService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private toastr: ToastrService
        ) {}
  
    ngOnInit() {}
  
    closeModal() {
      this.passEntry.emit(null);
    }
  
    addWoolType(form: NgForm): void {

      this.passEntry.emit(true);

      const data = { name: form.controls.name.value };
      this.albaranService.addWoolType(data).subscribe( response => {

          this.ngxUiLoaderService.stopLoader('saveWoolType');
          this.passEntry.emit(false);

          if(!!response){
              (!response.error) 
                  ? this.toastr.success(`Nuevo tipo de lana ${ data.name } añadido con exito`, '¡Añadido!') 
                  : this.toastr.error(response.error, 'Oops!');
          } else {
              this.toastr.error('Problema al crear el nuevo tipo de lana', 'Oops!');
          }

          this.passEntry.emit(null);
              
      });
    }
  }
  