import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Cooperativa as CooperativaInterface } from '../../../../interfaces/cooperativa';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../../../../component/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  @Input() public cooperativa: CooperativaInterface;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('heroForm') heroForm: any;

  errorIdentifier = null;
  identifierOriginal: any;

  constructor(private apiService: ApiService, private modalService: NgbModal) {}

  ngOnInit() {
    this.identifierOriginal = this.cooperativa.identifier;
  }

  closeModal(refresh: any) {
    this.passEntry.emit(refresh);
  }

  editCooperativa(form: NgForm): void {
    Object.assign(this.cooperativa, form.value);
    if (this.cooperativa.identifier === this.identifierOriginal) {
      this.apiService
        .editCooperativa(this.cooperativa)
        .subscribe((data: any) => {
          this.closeModal(true);
        });
    } else {
      this.checkIdentifier(form.value.identifier, result => {
        this.errorIdentifier = result;
        if (!result) {
          this.apiService
            .editCooperativa(this.cooperativa)
            .subscribe((data: any) => {
              this.closeModal(true);
            });
        }
      });
    }
  }

  removeCooperativa(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'light-blue-backdrop'
    });
    modalRef.componentInstance.textMessage =
      '¿Estás seguro que desea eliminar la cooperativa?';

    modalRef.componentInstance.passConfirm.subscribe(confirm => {
      if (confirm) {
        this.apiService
          .deleteCooperativa(this.cooperativa)
          .subscribe((data: any) => {
            this.closeModal(false);
          });
      }
      modalRef.close();
    });
    modalRef.result.then(
      result => {
        modalRef.close();
      },
      reason => {
        modalRef.close();
      }
    );
  }

  checkIdentifier(
    identifier: number,
    callback: (result: boolean) => any
  ): void {
    this.apiService
      .checkIdentifierCooperativa(identifier)
      .subscribe((data: any) => {
        callback(data.length !== 0);
      });
  }

  onFocus() {
    this.errorIdentifier = null;
  }
}
