import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddTaraLanaComponent } from '../add-tara-lana/add-tara-lana.component';
import { ApiAlbaranService } from '../../../albaranes/services/api-albaran.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-add-tipo-lana',
  templateUrl: './add-tipo-lana.component.html',
  styleUrls: ['./add-tipo-lana.component.scss']
})
export class AddTipoLanaComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('tipoLanaForm') tipoLanaFormChild: any;

  excedeNeto = false;
  tipoLanaForm: {} = {
    tipo: null,
    kg_brutos: null,
    kg_netos: null
  };

  arrayTaras: {}[] = [];

  arrayTipoLana: string[] = [];

  arrayTipoEmbalaje: Object[] = [
    {
      nombre: 'Balote',
      numero: 2
    },
    {
      nombre: 'Saca',
      numero: 1.5
    },
    {
      nombre: 'Sacos',
      numero: 0.25
    },
    {
      nombre: 'BigBag',
      numero: 3
    }
  ];

  constructor(
    private modalService: NgbModal, 
    private apiAlbaranService: ApiAlbaranService) {}

  ngOnInit() {
    this.searchWoolTypes();
  }

  public searchWoolTypes() {
    this.apiAlbaranService.getWoolTypes().subscribe((data: any) =>
    {
      this.arrayTipoLana = (data || []).map(type => type.name);

      // Ahora, eliminamos los tipos que ya no interesan
      this.arrayTipoLana = this.arrayTipoLana.filter(function(value, index, arr)
      {
        return value != "LANA ROÑA" && value != "LANA CON ROÑA" && value != "LAN ACON ROÑA NEGRA";
      });
    });
  }


  closeModal() {
    this.passEntry.emit(null);
  }

  addTipo(form: NgForm): void {
    const lana = {
      tipo: form.value['tipo'],
      kg_brutos: form.value['kg_brutos'],
      kg_netos: form.value['kg_netos'],
      tara: [...this.arrayTaras]
    };
    this.passEntry.emit(lana);
  }

  addTara(): void {
    console.log('Add tara');

    const modalRef = this.modalService.open(AddTaraLanaComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'light-blue-backdrop'
    });

    modalRef.componentInstance.passEntry.subscribe((newTara: any) => {
      console.log(newTara);
      if (newTara !== null) {
        this.arrayTaras.unshift(newTara);
        this.calcularNeto();
      }
      modalRef.close();
    });
  }

  calcularNeto(): void {
    console.log(this.tipoLanaFormChild.controls['tipo'].value);

    this.tipoLanaForm['kg_netos'] = 0;

    let valid = true;
    let cont = 0;

    for (const tara of this.arrayTaras) {
      console.log(tara);
      if (
        tara['embalaje'] === '' ||
        tara['embalaje'] === null ||
        tara['numero'] === null ||
        tara['numero'] <= 0
      ) {
        valid = false;
      } else {
        if (tara['embalaje'] !== 'Otras taras') {
          cont +=
            this.arrayTipoEmbalaje[
              this.arrayTipoEmbalaje
                .map(function(e: any) {
                  return e.nombre;
                })
                .indexOf(tara['embalaje'])
            ]['numero'] * tara['numero'];
        } else {
          cont += tara['numero'];
        }
      }
    }
    if (valid) {
      this.tipoLanaFormChild.setValue({
        tipo: this.tipoLanaFormChild.controls['tipo'].value,
        kg_netos: Math.ceil(
          this.tipoLanaFormChild.controls['kg_brutos'].value - cont
        ),
        kg_brutos: Math.ceil(this.tipoLanaFormChild.controls['kg_brutos'].value)
      });

      this.excedeNeto = this.tipoLanaFormChild.controls['kg_netos'].value >= 0;
    }
  }

  deleteTara(index: number): void {
    this.arrayTaras.splice(index, 1);
    this.calcularNeto();
  }
}
