import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map';
import { environment } from './../../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginContext } from '../login/login.component';
import { Router, ActivatedRoute } from '@angular/router';

export interface Credentials {
  // Customize received credentials here
  token: string;
  role: string;
}

const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
	private observable: Observable<Credentials>;

	constructor(public jwtHelper: JwtHelperService, private http: HttpClient, private router: Router) {}

	public isAuthenticated(): boolean
	{
		const token = localStorage.getItem('token');
		// Check whether the token is expired and return
		// true or false
		// console.log(this.jwtHelper.isTokenExpired(token));
		return token != null && !this.jwtHelper.isTokenExpired(token);
	}

	/**
	 * Authenticates the user.
	 * @param {LoginContext} context The login parameters.
	 * @return {Observable<Credentials>} The user credentials.
	 */
	login(context: LoginContext): Observable<Credentials>
	{
		// Replace by proper authentication call
		// console.log(this.http);
		const user =
		{
			username: context.username,
			password: context.password
		};

		this.observable = this.http.post(`${environment.serverUrl}trabajadores/authenticate`, user).map(response =>
		{
			// when the cached data is available we don't need the `Observable` reference anymore
			this.observable = null;

			const data: Credentials =
			{
				token: response['token'],
				role: response['role']
			};
			this.setCredentials(data, context.remember);
			return data;

			// make it shared so more than one subscriber can get the result
		});

		return this.observable;
	}

	/**
	 * Logs out the user and clear credentials.
	 * @return {Observable<boolean>} True if the user was logged out successfully.
	 */
	logout(): any
	{
		// Customize credentials invalidation here
		this.setCredentials(null, true);
		this.router.navigate(['/authentication'], { replaceUrl: true });
		return of(true);
	}

	currentUserValue(): Credentials
	{
		const credentials: Credentials =
		{
			token: localStorage.getItem('token'),
			role: localStorage.getItem('role')
		};

		return credentials;
	}

	/**
	 * Sets the user credentials.
	 * The credentials may be persisted across sessions by setting the `remember` parameter to true.
	 * Otherwise, the credentials are only persisted for the current session.
	 * @param {Credentials=} credentials The user credentials.
	 * @param {boolean=} remember True to remember credentials across sessions.
	 */
	private setCredentials(credentials?: Credentials, remember?: boolean)
	{
		if (credentials)
		{
			const storage = remember ? localStorage : sessionStorage;
			storage.setItem('token', credentials['token']);
			storage.setItem('role', credentials['role']);
		}
		else
		{
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('role');
			localStorage.removeItem('token');
			localStorage.removeItem('role');
		}
	}


	// 17-03-2022 Función simplificada mas abajo
	/*getAuthToken(): string {
		const token = localStorage.getItem('token');

		if (token != null) {
		return token;
		}

		return '';
	}*/

	getAuthToken(): string
	{
		const token = localStorage.getItem('token');
		return token != null ? token : '';
	}

	public getRole()
	{
		const role = localStorage.getItem('role');
		return role != null ? role : '';
	}
}
