import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Albaran as AlbaranInterface } from '../../interfaces/albaran';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { validateStyleParams } from '@angular/animations/browser/src/util';

@Injectable({
  providedIn: 'root'
})
export class ApiAlbaranService {
  constructor(private http: HttpClient) {}

  public getSociosByCooperativa(idCooperativa: string): Observable<any> {
    const params = new HttpParams().set('idCooperativa', idCooperativa);

    return this.http
      .get(`${environment.serverUrl}albaranes/getSociosByCooperativa`, {
        params: params
      })
      .pipe(map(this.extractData));
  }

  public getIdentifierAuto(): Observable<any> {
    return this.http
      .get(`${environment.serverUrl}albaranes/getIdentifierAuto`)
      .pipe(map(this.extractData));
  }

  public checkNumeroAlbaran(identifier: string, tipo: string): Observable<any> {
    const params = new HttpParams()
      .set('identifier', identifier)
      .set('tipo', tipo);

    return this.http
      .get(`${environment.serverUrl}albaranes/checkNumeroAlbaran`, {
        params: params
      })
      .pipe(map(this.extractData));
  }

  public addAlbaran(albaran: {}): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}albaranes/create`, albaran)
      .pipe(map(this.extractData));
  }

	public getAlbaranes(idCooperativa: string, idSeason: string, initDate: string, endDate: string): Observable<any>
	{
    	let params = new HttpParams().set('idCooperativa', idCooperativa).set('idSeason', idSeason).set('initDate', initDate).set('endDate', endDate);

		return this.http.get(`${environment.serverUrl}albaranes/getAll`,
		{
			params: params
		}).pipe(map(this.extractData));
	}

  public editAlbaran(albaran: {}): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}albaranes/albaran`, albaran)
      .pipe(map(this.extractData));
  }

  public deleteAlbaran(albaran: AlbaranInterface): Observable<any> {
    const httpParams = new HttpParams().set('id', albaran['id']);

    return this.http
      .delete(`${environment.serverUrl}albaranes/albaran`, {
        params: httpParams
      })
      .pipe(map(this.extractData));
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public getWoolTypes(): Observable<any> {
    return this.http
      .get(`${environment.serverUrl}wool`)
      .pipe(map(this.extractData));
  }

  public addWoolType(wool): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}wool/create`, wool)
      .pipe(map(this.extractData));
  }


}
