import { Component } from '@angular/core';
import { Albaran as AlbaranInterface } from './../../interfaces/albaran';
import { ApiService as ApiServiceCooperativa } from './../../admin/cooperativa/services/api.service';
import * as moment from 'moment';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { WoolTypes } from './print-albaran.enum';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'print-albaran-page',
  templateUrl: './print-albaran.page.html',
  styleUrls: ['./print-albaran.page.scss']
})
export class PrintAlbaranPage
{
	public albaran: AlbaranInterface;
	public cooperative: string = '';
	public date: any;
	public observations1: string = '';
	public observations2: string = '';
	public subcription: Subscription;
	public wool: any = {};
	public containerString: string = "";
	public containerRaw: any[] = [];

	// 01-05-2022 Ya no es necesario este sistema, se sustituye por uno que muestre todos los envases
	// public container: string[] = [];

	constructor(private apiServiceCooperativa: ApiServiceCooperativa, private notificationService: NotificationService, private printService: PrintService)
	{ 
		this.subcription = this.notificationService.on('albaran').subscribe( content =>
		{
			this.albaran = null;
			this.cooperative = '';
			this.date = null;
			this.observations1 = '';
			this.observations2 = '';
			this.wool =
			{
				merina: { grossKg: '', fees: '', netKg: ''},
				entrefina: { grossKg: '', fees: '', netKg: ''},
				lacona: { grossKg: '', fees: '', netKg: ''},
				pelo: { grossKg: '', fees: '', netKg: ''},
				negro: { grossKg: '', fees: '', netKg: ''},
				campanica: { grossKg: '', fees: '', netKg: ''},
				aninoBlanco: { grossKg: '', fees: '', netKg: ''},
				aninoGris: { grossKg: '', fees: '', netKg: ''},
				aninoNegro: { grossKg: '', fees: '', netKg: ''},
				others: { grossKg: '', fees: '', netKg: ''},
			};

			this.getContent(content);
		});
	}

	public getContent(albaran)
	{
		this.albaran = albaran;

		// Vaciamos este array para que no mantenga datos de un albarán anterior al haber impreso albaranes anteriores
		this.containerRaw = [];

		this.date = (!!this.albaran.date) ? moment(new Date(this.albaran.date.year, this.albaran.date.month - 1, this.albaran.date.day)).format('DD/MM/YYYY') : null;

		if(!!this.albaran.observaciones)
		{
			if(this.albaran.observaciones.length > 98)
			{
				this.observations1 = (this.albaran.observaciones.substr(0,98)).toUpperCase(); 
				this.observations2 = (this.albaran.observaciones.substr(98)). toUpperCase();
			}
			else
				this.observations1 = (this.albaran.observaciones).toUpperCase();
		}

		this.cooperative = this.albaran.cooperativa;

		this.wool.new = [];

		this.albaran.lanas.forEach( wool =>
		{
			// 01-05-2022 Ya no uso este sistema
			// this.container = (wool['tara'] || []).map( c => `${c.numero} ${c.embalaje.toUpperCase()}`).join(', ');

			let tipo = null;

			switch(wool.tipo) {
			case WoolTypes.MERINA:
				this.wool.merina = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 1;
				break;

			case WoolTypes.ENTREFINA:
				this.wool.entrefina = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 2;
				break;
				
			case WoolTypes.PELO:
				this.wool.pelo = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 3;
				break;      

			case WoolTypes.LACONA:
				this.wool.lacona = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 4;
				break;

			case WoolTypes.NEGRA:
				this.wool.negro = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 5;
				break;

			case WoolTypes.CAMPANICA:
				this.wool.campanica = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 6;
				break;
				
			case WoolTypes.ANINO_BLANCO:
				this.wool.aninoBlanco = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 7;
				break;
				
			case WoolTypes.ANINO_GRIS:
				this.wool.aninoGris = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 8;
				break;
				
			case WoolTypes.ANINO_NEGRO:
				this.wool.aninoNegro = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 9;
				break;

			case WoolTypes.OTHERS:
				this.wool.others = { grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos };
				tipo = 10;
				break;   
				
			default: 
				const width = Math.trunc((wool.tipo.length * 11) + 162.35) + 1;
				this.wool.new.push({ grossKg: wool.kg_brutos, fees: (wool.kg_brutos - wool.kg_netos), netKg: wool.kg_netos, name: wool.tipo, width: `${width}px`, discountWidth: `${ 517 - width }px` });
				tipo = 11;
			}

			for (let embalaje of wool['tara'])
			{
				this.containerRaw.push({
					embalaje: embalaje.embalaje.toUpperCase(),
					numero: embalaje.numero,
					tipo: tipo
				})
			}
		});

		// Una vez obtenidos todos los tipos de embalaje, se ordenan según el tipo (para que aparezcan en el mismo orden que en las líneas del albarán)
		this.containerRaw.sort((n1,n2) => n1.tipo - n2.tipo)

		// Ahora, convertimos este array en un string, con la información de los envases que se mostrará en el albarán
		if (this.containerRaw.length > 0)
		{
			this.containerString = this.containerRaw[0].numero + " " + this.containerRaw[0].embalaje

			if (this.containerRaw.length > 1)
			{
				for (let index = 1; index < this.containerRaw.length; index++)
				{
					this.containerString += " + " + this.containerRaw[index].numero + " " + this.containerRaw[index].embalaje
				}
			}
		}
	}

	ngOnDestroy()
	{
		if(!!this.subcription) this.subcription.unsubscribe();
	}
}