import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiSocioService } from '../../services/api-socio.service';
import { ApiService } from '../../../cooperativa/services/api.service';

import { Socio as SocioInterface } from '../../../../interfaces/socio';
import { Cooperativa as CooperativaInterface } from '../../../../interfaces/cooperativa';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../../../../component/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-edit-socio',
  templateUrl: './edit-socio.component.html',
  styleUrls: ['./edit-socio.component.scss']
})
export class EditSocioComponent implements OnInit {
  @Input() public socio: SocioInterface;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('heroForm') heroForm: any;

  loadingIndicator: Boolean = false;
  cooperativas: CooperativaInterface[];

  errorIdentifier = null;
  identifierOriginal: any;
  cooperativaOriginal: any;
  grupoOriginal: any;

  grupoArray: any[] = [
    { id: 1, nombre: 'Grupo 1' },
    { id: 2, nombre: 'Grupo 2' },
    { id: 3, nombre: 'Grupo 3' },
    { id: 4, nombre: 'Grupo 4' }
  ];

  constructor(
    private apiService: ApiSocioService,
    private apiServiceCooperativa: ApiService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    console.log(this.socio);
    this.searchCooperativas();
    this.identifierOriginal = this.socio.identifier;
    this.cooperativaOriginal = this.socio.cooperativa['id'];
    this.grupoOriginal = this.socio.cooperativa['grupo'];
  }

  closeModal(refresh: any) {
    this.passEntry.emit(refresh);
  }

  editSocio(form: NgForm): void {
    Object.assign(this.socio, form.value);
    if (this.socio.identifier === this.identifierOriginal) {
      this.editSocioAPI(this.socio);
    } else {
      this.checkIdentifier(
        form.value.identifier,
        form.value.cooperativaSocio,
        result => {
          this.errorIdentifier = result;
          if (!result) {
            this.editSocioAPI(this.socio);
          }
        }
      );
    }
  }

  removeSocio(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'light-blue-backdrop'
    });
    modalRef.componentInstance.textMessage =
      '¿Estás seguro que desea eliminar el socio?';

    modalRef.componentInstance.passConfirm.subscribe(confirm => {
      if (confirm) {
        this.apiService.deleteSocio(this.socio).subscribe((data: any) => {
          this.closeModal(false);
        });
      }
      modalRef.close();
    });
    modalRef.result.then(
      result => {
        modalRef.close();
      },
      reason => {
        modalRef.close();
      }
    );
  }

  private editSocioAPI(socio: SocioInterface): void {
    this.apiService.editSocio(socio).subscribe((data: any) => {
      this.closeModal(true);
    });
  }

  checkIdentifier(
    identifier: number,
    idCooperativa: string,
    callback: (result: boolean) => any
  ): void {
    this.apiService
      .checkIdentifierSocio(identifier, idCooperativa)
      .subscribe((data: any) => {
        callback(data.length !== 0);
      });
  }

  onFocus(): void {
    this.errorIdentifier = null;
  }

  searchCooperativas(): void {
    this.loadingIndicator = true;
    this.apiServiceCooperativa.getCooperativas().subscribe((data: any) => {
      this.cooperativas = [...data];
      this.loadingIndicator = false;
    });
  }

  changeCooperativa(newCooperativa: string): void {
    this.apiService
      .changeCooperativa(
        newCooperativa,
        this.cooperativaOriginal,
        this.socio['_id'],
        this.grupoOriginal,
        this.socio['identifier']
      )
      .subscribe((data: any) => {
        if (Object.keys(data).length === 0 && data.constructor === Object) {
          this.errorIdentifier = true;
        } else {
          this.cooperativaOriginal = newCooperativa;
        }
      });
  }

  changeGrupo(newGrupo: string): void {
    this.apiService
      .changeGrupo(
        newGrupo,
        this.grupoOriginal,
        this.socio['_id'],
        this.cooperativaOriginal
      )
      .subscribe((data: any) => {
        this.grupoOriginal = newGrupo;
      });
  }
}
