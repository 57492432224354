// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment =
{
	/*production: false,
	serverUrl: 'http://localhost:4000/',
	season_default: '5f38fd7b70eab61e3c7d1777'*/
	production: true,
	serverUrl: 'https://api.filotat.com/',
	season_default: '5f5f9bb0a329216b50b4a4da'
};
 