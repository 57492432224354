import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { Trabajador as TrabajadorInterface } from '../../../interfaces/trabajador';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiTrabajadorService {
  constructor(private http: HttpClient) {}

  public getTrabajadores(): Observable<any> {
    return this.http
      .get(`${environment.serverUrl}trabajadores`)
      .pipe(map(this.extractData));
  }

  public checkIdentifierTrabajador(identifier: number): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}trabajadores/checkIdentifier`, {
        identifier: identifier
      })
      .pipe(map(this.extractData));
  }

  public checkUsernameTrabajador(username: string): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}trabajadores/checkUsername`, {
        username: username
      })
      .pipe(map(this.extractData));
  }

  public addTrabajador(trabajador: TrabajadorInterface): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}trabajadores/create`, trabajador)
      .pipe(map(this.extractData));
  }

  public editTrabajador(trabajador: TrabajadorInterface): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}trabajadores/edit`, trabajador)
      .pipe(map(this.extractData));
  }

  public deleteTrabajador(trabajador: TrabajadorInterface): Observable<any> {
    const httpParams = new HttpParams().set('id', trabajador['id']);

    return this.http
      .delete(`${environment.serverUrl}trabajadores/delete`, {
        params: httpParams
      })
      .pipe(map(this.extractData));
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
