import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiTrabajadorService } from '../../services/api-trabajador.service';
import { Trabajador as TrabajadorInterface } from '../../../../interfaces/trabajador';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../../../../component/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-edit-trabajador',
  templateUrl: './edit-trabajador.component.html',
  styleUrls: ['./edit-trabajador.component.scss']
})
export class EditTrabajadorComponent implements OnInit {
  @Input() public trabajador: TrabajadorInterface;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('heroForm') heroForm: any;

	errorIdentifier = null;
	identifierOriginal: any;

	errorUsername = null;
	usernameOriginal: any;

	roles: {}[] =
	[
		{ id: null, nombre: 'Seleciona un puesto para el trabajador...' },
		{ id: 'SuperUser', nombre: 'Super usuario' },
		{ id: 'Administrativo', nombre: 'Administrativo' },
		{ id: 'OperarioCalidad', nombre: 'Operario de calidad' }
	];

  constructor(
    private apiService: ApiTrabajadorService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.identifierOriginal = this.trabajador.identifier;
    this.usernameOriginal = this.trabajador.username;
  }

  closeModal(refresh: any) {
    this.passEntry.emit(refresh);
  }

  editTrabajador(form: NgForm): void {
    Object.assign(this.trabajador, form.value);
    if (
      this.trabajador.identifier === this.identifierOriginal &&
      this.trabajador.username === this.usernameOriginal
    ) {
      this.editTrabajadorAPI(this.trabajador);
    } else {
      if (this.trabajador.identifier !== this.identifierOriginal) {
        this.checkIdentifier(form.value.identifier, result => {
          this.errorIdentifier = result;
          if (!result) {
            if (this.trabajador.username !== this.usernameOriginal) {
              this.checkUsername(form.value.username, resultUsername => {
                this.errorUsername = resultUsername;
                if (!resultUsername) {
                  this.editTrabajadorAPI(this.trabajador);
                }
              });
            } else {
              this.errorUsername = null;
              this.editTrabajadorAPI(this.trabajador);
            }
          }
        });
      } else {
        this.errorIdentifier = null;
        this.checkUsername(form.value.username, resultUsername => {
          this.errorUsername = resultUsername;
          if (!resultUsername) {
            this.editTrabajadorAPI(this.trabajador);
          }
        });
      }
    }
  }

  removeTrabajador(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'light-blue-backdrop'
    });
    modalRef.componentInstance.textMessage =
      '¿Estás seguro que desea eliminar el trabajador?';

    modalRef.componentInstance.passConfirm.subscribe(confirm => {
      if (confirm) {
        this.apiService
          .deleteTrabajador(this.trabajador)
          .subscribe((data: any) => {
            this.closeModal(false);
          });
      }
      modalRef.close();
    });
    modalRef.result.then(
      result => {
        modalRef.close();
      },
      reason => {
        modalRef.close();
      }
    );
  }

  private editTrabajadorAPI(trabajador: TrabajadorInterface): void {
    this.apiService.editTrabajador(trabajador).subscribe((data: any) => {
      this.closeModal(true);
    });
  }

  checkIdentifier(
    identifier: number,
    callback: (result: boolean) => any
  ): void {
    this.apiService
      .checkIdentifierTrabajador(identifier)
      .subscribe((data: any) => {
        callback(data.length !== 0);
      });
  }

  checkUsername(username: string, callback: (result: boolean) => any): void {
    this.apiService.checkUsernameTrabajador(username).subscribe((data: any) => {
      callback(data.length !== 0);
    });
  }

  onFocus() {
    this.errorIdentifier = null;
    this.errorUsername = null;
  }
}
