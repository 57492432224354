import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { Socio as SocioInterface } from '../../../interfaces/socio';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiSocioService {
  constructor(private http: HttpClient) {}

  public getSocios(): Observable<any> {
    return this.http
      .get(`${environment.serverUrl}socios`)
      .pipe(map(this.extractData));
  }

  public checkIdentifierSocio(
    identifier: number,
    idCooperativa: string
  ): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}socios/checkIdentifier`, {
        identifier: identifier,
        idCooperativa: idCooperativa
      })
      .pipe(map(this.extractData));
  }

  public addSocio(socio: SocioInterface): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}socios/create`, socio)
      .pipe(map(this.extractData));
  }

  public editSocio(socio: SocioInterface): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}socios/edit`, socio)
      .pipe(map(this.extractData));
  }

  public deleteSocio(socio: SocioInterface): Observable<any> {
    const httpParams = new HttpParams().set('id', socio['id']);

    return this.http
      .delete(`${environment.serverUrl}socios/delete`, {
        params: httpParams
      })
      .pipe(map(this.extractData));
  }

  public changeCooperativa(
    newCooperativa: string,
    oldCooperativa: string,
    idSocio: String,
    grupo: string,
    identifier: String
  ): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}socios/changeCooperativa`, {
        newCooperativa: newCooperativa,
        oldCooperativa: oldCooperativa,
        idSocio: idSocio,
        grupo: grupo,
        identifier: identifier
      })
      .pipe(map(this.extractData));
  }

  public changeGrupo(
    newGrupo: string,
    oldGrupo: string,
    idSocio: String,
    idCooperativa: string
  ): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}socios/changeGrupo`, {
        newGrupo: newGrupo,
        oldGrupo: oldGrupo,
        idSocio: idSocio,
        idCooperativa: idCooperativa
      })
      .pipe(map(this.extractData));
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
