import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})

export class PrintService {
  public page: string = '';
  public content: any;

  constructor(
    private notificationService: NotificationService
  ){

  }

  public printPage() {
    this.notificationService.raise(this.page, this.content);
    setTimeout(()=> window.print(), 500);
  }

}