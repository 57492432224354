import {
  Component,
  Injectable,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  @Input() textMessage: String;
  @Output() passConfirm: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeModal(confirm: boolean) {
    this.passConfirm.emit(confirm);
  }
}
