import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { EditComponent } from './admin/cooperativa/components/edit/edit.component';
import { EditTrabajadorComponent } from './admin/trabajador/components/edit-trabajador/edit-trabajador.component';
import { EditSocioComponent } from './admin/socio/components/edit-socio/edit-socio.component';
import { ModalConfirmComponent } from './component/modal-confirm/modal-confirm.component';
import { ModalAlertComponent } from './component/modal-alert/modal-alert.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AddTipoLanaComponent } from './albaranes/components/add-tipo-lana/add-tipo-lana.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddTaraLanaComponent } from './albaranes/components/add-tara-lana/add-tara-lana.component';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './parser/ngb-date-frparser-formatter';
import { PrintAlbaranPage } from './albaranes/print-albaran/print-albaran.page';
import { NotificationService } from './services/notification.service';
import { NewWoolTypeComponent } from './albaranes/components/new-wool-type/new-wool-type.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    EditComponent,
    EditTrabajadorComponent,
    EditSocioComponent,
    ModalConfirmComponent,
    ModalAlertComponent,
    AddTipoLanaComponent,
    AddTaraLanaComponent,
    NewWoolTypeComponent,
    PrintAlbaranPage
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false }),
    PerfectScrollbarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [''], // White List
        blacklistedRoutes: [''] // Bad route
      }
    }),
    NgSelectModule
  ],
  entryComponents: [
    EditComponent,
    ModalConfirmComponent,
    ModalAlertComponent,
    EditTrabajadorComponent,
    EditSocioComponent,
    AddTipoLanaComponent,
    NewWoolTypeComponent,
    AddTaraLanaComponent
  ],
  providers: [
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
