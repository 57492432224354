import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { Cooperativa as CooperativaInterface } from '../../../interfaces/cooperativa';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public getCooperativas(): Observable<any> {
    return this.http
      .get(`${environment.serverUrl}cooperativas`)
      .pipe(map(this.extractData));
  }

  public checkIdentifierCooperativa(identifier: number): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}cooperativas/checkIdentifier`, {
        identifier: identifier
      })
      .pipe(map(this.extractData));
  }

  public addCooperativa(cooperativa: CooperativaInterface): Observable<any> {
    return this.http
      .post(`${environment.serverUrl}cooperativas/create`, cooperativa)
      .pipe(map(this.extractData));
  }

  public editCooperativa(cooperativa: CooperativaInterface): Observable<any> {
    return this.http
      .put(`${environment.serverUrl}cooperativas/edit`, cooperativa)
      .pipe(map(this.extractData));
  }

  public deleteCooperativa(cooperativa: CooperativaInterface): Observable<any> {
    const httpParams = new HttpParams().set('id', cooperativa['id']);

    return this.http
      .delete(`${environment.serverUrl}cooperativas/delete`, {
        params: httpParams
      })
      .pipe(map(this.extractData));
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
