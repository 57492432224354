export enum WoolTypes {
    MERINA = 'Merina',
    ENTREFINA = 'Entrefina',
    PELO = 'Pelo',
    NEGRA = 'Negra',
    LACONA = 'Lacona',
    CAMPANICA ='Campaniça',
    ANINO_BLANCO ='Añino Blanco',
    ANINO_GRIS = 'Añino Gris',
    ANINO_NEGRO = 'Añino Negro',
    OTHERS = 'Otras lanas'
}