import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuardService as AuthGuard } from './authentication/guard/auth-guard.service';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboards/dashboard', pathMatch: 'full' },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
      {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule'
      },
      {
        path: 'albaranes',
        loadChildren: './albaranes/albaranes.module#AlbaranesModule'
      },
      {
        path: 'calidad',
        loadChildren: './calidad/calidad.module#CalidadModule'
      },
      {
        path: 'calidad2',
        loadChildren: './calidad2/calidad2.module#CalidadModule'
      },
      {
        path: 'bascula',
        loadChildren: './pressing/pressing.module#PressingModule'
      },
      {
        path: 'qr',
        loadChildren: './qr/qr.module#QrModule'
      },
      {
        path: 'campaña',
        loadChildren: './season/season.module#SeasonModule'
      },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      { path: 'forms', loadChildren: './form/forms.module#FormModule' },
      { path: 'tables', loadChildren: './table/tables.module#TablesModule' },
      { path: 'charts', loadChildren: './charts/charts.module#ChartModule' },
      {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'extra-component',
        loadChildren:
          './extra-component/extra-component.module#ExtraComponentsModule'
      },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
      {
        path: 'apps/email',
        loadChildren: './apps/email/mail.module#MailModule'
      },
      {
        path: 'sample-pages',
        loadChildren: './sample-pages/sample-pages.module#SamplePagesModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
