import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-add-tara-lana',
  templateUrl: './add-tara-lana.component.html',
  styleUrls: ['./add-tara-lana.component.scss']
})
export class AddTaraLanaComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('tipoTaraForm') tipoTaraFormChild: any;

  tipoTaraForm: {} = {
    embalaje: null,
    numero: null
  };

  arrayTipoEmbalaje: Object[] = [
    {
      nombre: 'Balote',
      numero: 2
    },
    {
      nombre: 'Saca',
      numero: 1.5
    },
    {
      nombre: 'Sacos',
      numero: 0.25
    },
    {
      nombre: 'BigBag',
      numero: 3
    },
    {
      nombre: 'Otras taras',
      numero: null
    }
  ];

  constructor() {}

  ngOnInit() {}

  closeModal() {
    this.passEntry.emit(null);
  }

  addTara(form: NgForm): void {
    this.passEntry.emit(form.value);
  }
}
